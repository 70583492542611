@charset "utf-8";
// @import "minima";

$base-font-family: Times, Times New Roman, serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;

$text-color:       #111 !default;
$background-color: #f3f3f3 !default;

// Width of the content area
$content-width:    935px !default;
$spacing-unit:     28px !default;
$item-width:       ($content-width - $spacing-unit * 2) / 3;

$on-palm:          600px !default;
$on-laptop:        800px !default;

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

body {
   font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
   color: $text-color;
   background-color: $background-color;
   margin: 0;
   padding: 0;
 }

a,
a:visited {
  color: $text-color;
}

.site-header {
  text-align: center;
}

.site-title {
  @include relative-font-size(4);
  font-weight: bold;
  margin: $spacing-unit * 2.5 0 $spacing-unit 0;
  line-height: 0.9;
}

.site-nav {
  margin: $spacing-unit 0;

  .page-link {
    font-family: Helvetica, sans-serif;

    &:not(:last-child) {
      margin-right: $spacing-unit;
    }
  }

  a.page-link {
    color: #b9b9b9;
    text-decoration: none;
  }

  a.page-link:hover {
    color: #333;
  }

  span.page-link {
    color: #333;
  }
}

.page-content {
  padding: $spacing-unit 0;
}

/**
 * Wrapper
 */
 .wrapper {
  width: $content-width;

  @include media-query($on-palm) {
    width: auto;
    max-width: $content-width;
  }

  margin: 0 auto;
}

/**
 * Site footer
 */
 .site-footer {
  padding: $spacing-unit 0;
}

.social-media-list {
  list-style-type: none;
  text-align: center;
  line-height: 1;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.social-media-list li {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 5px;
}

.work {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.work li {
  float: left;

  width: $item-width;
  height: $item-width;

  &:not(:last-child) {
    margin-right: $spacing-unit;
  }

  @include media-query($on-palm) {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-right: auto;
      margin-bottom: $spacing-unit;
    }
  }
}

.work li img {
  width: $item-width;
  height: $item-width;
}

@include media-query($on-palm) {
  .about {
    padding: 0 $spacing-unit;
  }
}
